// react/src/utils/axios.js
import axios from "axios";
import { getCsrfToken } from "./csrf";

const instance = axios.create({
  baseURL: "https://vestibularunifatecie.com/api",
  withCredentials: true, // Certifique-se de que 'withCredentials' está configurado corretamente para enviar cookies.
  headers: {
    "X-CSRFToken": getCsrfToken(), // Inclua o token CSRF nos headers.
  },
});

instance.interceptors.request.use((config) => {
  config.headers["X-CSRFToken"] = getCsrfToken();
  return config;
});

export default instance;
