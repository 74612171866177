// react/src/pages/Login.js
import React, { Component, Fragment } from "react";
import axios from "../utils/axios";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      academicRecord: "",
      password: "",
      error: "",
      loading: false,
      showModal: false,
    };
  }

  async componentDidMount() {
    await this.ensureCsrfToken(); // Garante que o token CSRF seja carregado
    this.checkSession(); // Verifica se já existe uma sessão ativa
  }

  // Obtém o token CSRF ao carregar a página
  ensureCsrfToken = async () => {
    try {
      await axios.get("/api/login/"); // Corrija a URL para garantir que o token CSRF seja carregado corretamente.
    } catch (error) {
      console.error("Erro ao obter o token CSRF:", error.message);
    }
  };

  // Verifica se a sessão ainda é válida
  checkSession = () => {
    const sessionExpiration = localStorage.getItem("sessionExpiration");

    if (sessionExpiration) {
      const expirationTime = new Date(sessionExpiration);
      const currentTime = new Date();

      if (currentTime < expirationTime) {
        this.props.history.push("/exam");
      }
    }
  };

  // Atualiza o estado com base nos inputs do usuário
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  // Submissão do formulário de login
  handleSubmit = async (event) => {
    event.preventDefault();
    const { academicRecord, password } = this.state;

    this.setState({ loading: true, error: "" });

    try {
      const response = await axios.post("/api/login/", {
        // Corrija o caminho da URL
        username: academicRecord,
        password: password,
      });

      if (response.status === 200) {
        console.log("Login bem-sucedido!");

        const { redirect_url, has_submitted_essay, userType } = response.data;

        localStorage.setItem("userType", userType);

        const sessionExpiration = new Date();
        sessionExpiration.setHours(sessionExpiration.getHours() + 24);
        localStorage.setItem(
          "sessionExpiration",
          sessionExpiration.toISOString()
        );

        if (has_submitted_essay) {
          alert(
            "Sua redação já foi submetida. O resultado será enviado por e-mail em até 5 dias úteis. Por favor, verifique também sua caixa de spam."
          );
        }

        this.props.history.push(redirect_url);
      }
    } catch (error) {
      console.error("Erro ao tentar autenticar:", error);
      this.setState({
        error:
          "Erro na autenticação. Por favor confira suas credenciais e tente novamente.",
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  // Alterna a exibição do modal
  toggleModal = () => {
    this.setState((prevState) => ({ showModal: !prevState.showModal }));
  };

  render() {
    const { academicRecord, password, error, loading, showModal } = this.state;

    return (
      <Fragment>
        <div className="main-wrap">
          {/* Conteúdo do login */}
          <div className="row">
            <div className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat">
              <img
                src={`${process.env.PUBLIC_URL}/static/images/login-banner.png`}
                alt="Login Banner"
                className="w-100 vh-100"
              />
            </div>
            <div className="col-xl-7 login-container">
              <div className="card shadow-none border-0 login-card bg-light p-4 rounded-lg">
                <div className="card-body rounded-0 text-left">
                  <h2 className="fw-700 display1-size display2-md-size mb-3">
                    Acessar <br />
                    sua conta
                  </h2>
                  <form onSubmit={this.handleSubmit}>
                    <div className="form-group icon-input mb-3">
                      <i className="font-sm ti-email text-grey-500 pr-0"></i>
                      <input
                        type="text"
                        name="academicRecord"
                        className="style2-input pl-5 form-control text-grey-900 font-xssss fw-600"
                        placeholder="Registro Acadêmico (RA)"
                        value={academicRecord}
                        onChange={this.handleChange}
                        disabled={loading}
                      />
                    </div>
                    <div className="form-group icon-input mb-1">
                      <input
                        type="password"
                        name="password"
                        className="style2-input pl-5 form-control text-grey-900 font-xssss ls-3"
                        placeholder="Senha"
                        value={password}
                        onChange={this.handleChange}
                        disabled={loading}
                      />
                      <i className="font-sm ti-lock text-grey-500 pr-0"></i>
                    </div>
                    {error && (
                      <div className="alert alert-danger font-xssss">
                        {error}
                      </div>
                    )}
                    <div className="form-check text-left mb-3">
                      <input
                        type="checkbox"
                        className="form-check-input mt-2"
                        id="exampleCheck1"
                        disabled={loading}
                      />
                      <label
                        className="form-check-label font-xssss text-grey-500"
                        htmlFor="exampleCheck1"
                      >
                        Lembre-me
                      </label>
                      <button
                        type="button"
                        onClick={this.toggleModal}
                        className="fw-600 font-xssss text-grey-700 mt-1 float-right btn btn-link"
                        style={{ pointerEvents: loading ? "none" : "auto" }}
                      >
                        Não sabe sua conta?
                      </button>
                    </div>
                    <div className="col-sm-12 p-0 text-left">
                      <div className="form-group mb-1">
                        <button
                          type="submit"
                          className="form-control text-center style2-input text-white fw-600 bg-current border-0 p-0"
                          disabled={loading}
                        >
                          {loading ? "Aguarde..." : "Entrar"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              {loading && (
                <div className="loading-overlay d-flex justify-content-center align-items-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Carregando...</span>
                  </div>
                  <p className="text-white font-lg ml-3">
                    Sincronizando dados do aluno(a)...
                  </p>
                </div>
              )}

              {/* Modal de orientação estilizado */}
              {showModal && (
                <div className="custom-modal-overlay">
                  <div className="custom-modal-dialog">
                    <div className="custom-modal-content">
                      <div className="custom-modal-header">
                        <h5 className="custom-modal-title">
                          Não sabe sua conta?
                        </h5>
                        <button
                          type="button"
                          className="custom-modal-close"
                          onClick={this.toggleModal}
                        >
                          <span>&times;</span>
                        </button>
                      </div>
                      <div className="custom-modal-body">
                        <p>
                          Após a sua inscrição no curso escolhido, você recebeu
                          um e-mail contendo suas credenciais de acesso. O
                          e-mail inclui o seu Registro Acadêmico (RA) e a senha
                          para acesso às plataformas da UniFatecie, como o
                          Moodle e o AlunoNet.
                        </p>
                        <p>
                          A senha é composta pelos 6 dígitos correspondentes à
                          sua data de nascimento no formato DDMMYY. Por exemplo,
                          se a sua data de nascimento é 21/03/1999, a senha será
                          210399.
                        </p>
                        <p>
                          Caso não tenha recebido o e-mail ou não saiba qual é o
                          seu Registro Acadêmico, entre em contato conosco pelo
                          telefone (44) 99153-3415 para assistência.
                        </p>
                      </div>
                      <div className="custom-modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={this.toggleModal}
                        >
                          Fechar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Login;
