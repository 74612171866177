// react/src/index.js
import React, { Component } from "react";
import ReactDOM from "react-dom";

import "./main.scss";

import ExamPage from "./pages/ExamPage";
import AdminEssays from "./pages/AdminEssays";
import Admin from "./pages/Admin";
import Login from "./pages/Login";

import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import AdminRoute from "./AdminRoute";
import * as serviceWorker from "./serviceWorker";

class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <Switch>
          <Route exact path="/">
            <Redirect to="/login" />
          </Route>

          <Route exact path="/login" component={Login} />

          <AdminRoute exact path="/admin" component={Admin} />

          <AdminRoute exact path="/admin-essays" component={AdminEssays} />

          <PrivateRoute exact path="/exam" component={ExamPage} />
        </Switch>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));

serviceWorker.register();
