// react/src/AdminRoute.js
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isAuthenticated } from "./auth";

const AdminRoute = ({ component: Component, ...rest }) => {
  const isAdmin = localStorage.getItem("userType") === "admin";

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() && isAdmin ? (
          <Component {...props} />
        ) : (
          <Redirect to={`${process.env.PUBLIC_URL}/login`} />
        )
      }
    />
  );
};

export default AdminRoute;
