// react/src/components/StudentTopnav.js
import React, { Component } from "react";
import Darkbutton from "../components/Darkbutton";
import { withRouter } from "react-router-dom";
import axios from "../utils/axios"; // Usar a instância configurada de axios

class StudentTopnav extends Component {
  logoutUser = async () => {
    try {
      // Remover informações de sessão do localStorage
      localStorage.removeItem("sessionExpiration");
      localStorage.removeItem("userType");

      // Fazer logout usando a instância do axios configurada
      await axios.post("/logout/");

      // Redirecionar para a página de login após logout
      this.props.history.push("/login");
    } catch (error) {
      console.error("Erro ao fazer logout:", error);
      alert("Erro ao fazer logout. Por favor, tente novamente.");
    }
  };

  render() {
    return (
      <nav className="navbar navbar-expand navbar-light bg-orange topbar mb-4 static-top shadow-xs d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <a href="/" className="navbar-brand d-flex align-items-center">
            <img
              src={`${process.env.PUBLIC_URL}/static/images/unifatecie-white-logo.png`}
              alt="Logo Unifatecie"
              className="logo-image"
              style={{ height: "40px" }}
            />
          </a>
        </div>

        <ul className="navbar-nav ml-auto d-flex align-items-center flex-wrap">
          <li className="nav-item d-flex align-items-center mb-2 mb-lg-0">
            <Darkbutton />
          </li>

          <li className="nav-item d-flex align-items-center mb-2 mb-lg-0">
            <button
              className="btn btn-sm d-flex align-items-center justify-content-center"
              style={{
                backgroundColor: "#f44336",
                color: "#fff",
                padding: "10px 15px",
                height: "auto",
                borderRadius: "5px",
              }}
              onClick={this.logoutUser}
            >
              <i className="feather-log-out"></i>
              <span className="ml-2 d-none d-lg-inline text-gray-600 small">
                Sair
              </span>
            </button>
          </li>
        </ul>
      </nav>
    );
  }
}

export default withRouter(StudentTopnav);
