// react/src/pages/ExamPage.js
import React, { Component, Fragment } from "react";
import axios from "../utils/axios"; // Usar a instância configurada de axios
import Appfooter from "../components/Appfooter";
import StudentTopnav from "../components/StudentTopnav";
import { Modal, Button } from "react-bootstrap";

class ExamPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInstructions: true,
      showExam: false,
      showModal: false,
      showWarningModal: false,
      essayText: "",
      essayTitle: "",
      isSubmitted: false,
      timeLeft: 14400,
      loading: false,
      error: "",
    };
  }

  async componentDidMount() {
    this.checkSession();

    try {
      const response = await axios.get("/api/exam/"); // Utilizar a URL relativa

      if (response.status === 200) {
        this.setState({ loading: false });
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        await this.logoutUser();
      } else {
        this.setState({
          error: "Ocorreu um erro. Tente novamente mais tarde.",
          loading: false,
        });
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  checkSession = () => {
    const sessionExpiration = localStorage.getItem("sessionExpiration");

    if (!sessionExpiration || new Date() > new Date(sessionExpiration)) {
      this.props.history.push("/login");
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { essayText, essayTitle } = this.state;

    const lines = essayText.split("\n").filter((line) => line.trim() !== "");
    if (!essayText.trim() || lines.length <= 1) {
      this.setState({ showWarningModal: true });
      return;
    }

    this.setState({ loading: true, error: "" });

    try {
      const response = await axios.post(
        "/api/submit-essay/", // Utilizar a URL relativa
        { title: essayTitle, body: essayText }
      );

      if (response.status === 201) {
        alert(
          "Sua redação será corrigida pela instituição. Aguarde até 5 dias para receber e-mail de divulgação do resultado."
        );
        this.setState({ isSubmitted: true });

        await this.logoutUser();
      }
    } catch (error) {
      console.error("Erro ao enviar a redação:", error);
      this.setState({
        error: "Erro ao enviar a redação. Tente novamente mais tarde.",
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  logoutUser = async () => {
    try {
      localStorage.removeItem("sessionExpiration");
      localStorage.removeItem("userType");

      await axios.post("/api/logout/"); // Utilizar a URL relativa

      this.props.history.push("/login");
    } catch (error) {
      console.error("Erro ao fazer logout:", error);

      alert("Erro ao fazer logout. Por favor, tente novamente.");
    }
  };

  handleStartExam = () => {
    this.setState({ showModal: true });
  };

  confirmStartExam = () => {
    this.setState({
      showInstructions: false,
      showExam: true,
      showModal: false,
    });

    this.startTimer();
  };

  startTimer = () => {
    this.timer = setInterval(() => {
      const { timeLeft } = this.state;
      if (timeLeft > 0) {
        this.setState({ timeLeft: timeLeft - 1 });
      } else {
        clearInterval(this.timer);
        alert("O tempo acabou! Sua prova será submetida automaticamente.");
        this.handleSubmit(new Event("submit"));
      }
    }, 1000);
  };

  formatTime = (seconds) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = seconds % 60;
    return `${h.toString().padStart(2, "0")}:${m
      .toString()
      .padStart(2, "0")}:${s.toString().padStart(2, "0")}`;
  };

  closeWarningModal = () => {
    this.setState({ showWarningModal: false });
  };

  proceedWithSubmission = () => {
    this.setState({ isSubmitted: true, showWarningModal: false });
    clearInterval(this.timer);
  };

  render() {
    const {
      showInstructions,
      showExam,
      showModal,
      showWarningModal,
      essayText,
      essayTitle,
      isSubmitted,
      timeLeft,
      loading,
      error,
    } = this.state;

    if (loading) {
      return <div>Carregando...</div>;
    }

    if (error) {
      return <div>{error}</div>;
    }

    return (
      <Fragment>
        <div className="main-wrapper bg-lightblue ">
          <StudentTopnav />

          <div className=" align-items-center justify-content-center min-vh-100 ">
            <div className="middle-sidebar-bottom theme-dark-bg">
              <div className="middle-sidebar-left">
                <div className="middle-wrap">
                  {showInstructions && (
                    <div
                      className="card w-100 border-0 bg-white shadow-xs p-0 mb-4"
                      style={{ maxWidth: "800px" }}
                    >
                      {/* Diretrizes e botão de iniciar */}
                      <div className="card-body p-lg-5 p-4 w-100 border-0">
                        <h2 className="font-xl text-center text-dark fw-700 mb-4">
                          Diretrizes para o Vestibular
                        </h2>
                        {/* Diretrizes */}
                        <div className="diretrizes mb-4">
                          <p className="text-grey-800 font-xs mb-2">
                            <strong>1. Estrutura do Vestibular</strong>
                          </p>
                          <p className="text-grey-800 font-xs mb-2">
                            i. O vestibular será composto exclusivamente por uma
                            prova de redação em língua portuguesa de caráter
                            eliminatório;
                          </p>
                          <p className="text-grey-800 font-xs mb-2">
                            ii. A redação deverá ser escrita em texto único, com
                            limite máximo de 20 linhas;
                          </p>
                          <p className="text-grey-800 font-xs mb-2">
                            iii. O tema da redação será anunciado no início da
                            prova;
                          </p>
                          <p className="text-grey-800 font-xs mb-4">
                            iv. A redação deve abordar o tema proposto de forma
                            clara e objetiva, demonstrando coerência e coesão
                            textual;
                          </p>
                          <p className="text-grey-800 font-xs mb-2">
                            <strong>2. Condições de Realização</strong>
                          </p>
                          <p className="text-grey-800 font-xs mb-2">
                            i. A prova de redação será realizada em sessão única
                            com duração de 4 horas;
                          </p>
                          <p className="text-grey-800 font-xs mb-4">
                            ii. Os candidatos deverão utilizar o campo de edição
                            de texto disponível para elaboração da redação e
                            submeter o envio da prova.
                          </p>
                          <p className="text-grey-800 font-xs mb-2">
                            <strong>3. Critérios de Avaliação</strong>
                          </p>
                          <p className="text-grey-800 font-xs mb-2">
                            i. A redação será avaliada com base nos seguintes
                            critérios:
                          </p>
                          <ul
                            className="text-grey-800 font-xs mb-2"
                            style={{
                              paddingLeft: "20px",
                              listStyleType: "disc",
                            }}
                          >
                            <li>
                              a. Clareza e objetividade na exposição de ideias;
                            </li>
                            <li>b. Coerência e coesão textual;</li>
                            <li>c. Adequação ao tema proposto;</li>
                            <li>
                              d. Originalidade e criatividade na abordagem do
                              tema;
                            </li>
                            <li>e. Correção gramatical e ortográfica.</li>
                          </ul>
                          <p className="text-grey-800 font-xs mb-2">
                            ii. A prova de redação terá sua nota atribuída de
                            zero a dez;
                          </p>
                          <p className="text-grey-800 font-xs mb-2">
                            iii. Em qualquer caso será eliminado o candidato que
                            obtiver nota zero na prova de redação;
                          </p>
                          <p className="text-grey-800 font-xs mb-4">
                            iv. Os candidatos que tirarem nota inferior a 2,0
                            serão desclassificados automaticamente.
                          </p>
                          <p className="text-grey-800 font-xs mb-2">
                            <strong>4. Publicação dos Resultados</strong>
                          </p>
                          <p className="text-grey-800 font-xs mb-4">
                            i. O resultado da prova de redação será divulgado no
                            prazo de 5 dias após a realização do vestibular.
                          </p>
                          <p className="text-grey-800 font-xs mb-2">
                            <strong>5. Disposições Gerais</strong>
                          </p>
                          <p className="text-grey-800 font-xs mb-4">
                            i. A participação no vestibular implica na aceitação
                            total das regras estabelecidas neste edital.
                          </p>
                        </div>
                        <div className="text-center">
                          <button
                            className="btn btn-lg bg-danger text-white fw-600 text-uppercase"
                            onClick={this.handleStartExam}
                          >
                            Iniciar Avaliação
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                  {showExam && (
                    <div
                      className="card w-100 border-0 bg-white shadow-xs p-0 mb-4"
                      style={{ maxWidth: "800px" }}
                    >
                      <div className="card-body p-lg-5 p-4 w-100 border-0">
                        <h2 className="font-xl text-center text-dark fw-700 mb-4">
                          Prova de Redação
                        </h2>

                        {/* Exibe o contador de tempo durante a prova */}
                        <div className="text-center mb-4">
                          <span className="badge badge-danger font-lg">
                            Tempo Restante: {this.formatTime(timeLeft)}
                          </span>
                        </div>

                        {/* Instruções da Prova */}
                        <div className="instructions mb-4">
                          <h4 className="text-dark font-lg fw-600 mb-3">
                            Tema da Redação: Desafios e Oportunidades da
                            Transformação Digital na Sociedade Contemporânea
                          </h4>
                          <p className="text-grey-800 font-xs mb-3">
                            Vivemos em uma era marcada pela rápida evolução
                            tecnológica e pela transformação digital, que tem
                            reconfigurado profundamente diversos aspectos da
                            sociedade contemporânea. A integração de tecnologias
                            digitais em nossas vidas diárias tem trazido
                            desafios significativos, mas também abriu novas
                            oportunidades em diferentes setores, como educação,
                            trabalho e comunicação.
                          </p>
                          <p className="text-grey-800 font-xs mb-3">
                            Com base na temática apresentada, escreva uma
                            redação dissertativa abordando os seguintes
                            aspectos:
                          </p>
                          <ul
                            className="text-grey-800 font-xs mb-3"
                            style={{
                              paddingLeft: "20px",
                              listStyleType: "disc",
                            }}
                          >
                            <li>
                              <strong>A) Desafios:</strong> Quais são os
                              principais desafios que a transformação digital
                              impõe à sociedade? Considere aspectos como
                              privacidade, segurança e desigualdade.
                            </li>
                            <li>
                              <strong>B) Oportunidades:</strong> Quais são as
                              principais oportunidades oferecidas pela
                              digitalização em áreas como educação, trabalho e
                              comunicação?
                            </li>
                            <li>
                              <strong>C) Equilíbrio e Futuro:</strong> Como a
                              sociedade pode equilibrar os benefícios e desafios
                              da transformação digital para garantir um
                              desenvolvimento equitativo e sustentável?
                            </li>
                          </ul>
                          <p className="text-grey-800 font-xs mb-4">
                            Seu texto deve apresentar argumentos claros e bem
                            estruturados, evidenciando sua capacidade de análise
                            crítica e sua visão sobre a influência da
                            transformação digital na sociedade.
                          </p>
                        </div>

                        {!isSubmitted ? (
                          <form onSubmit={this.handleSubmit}>
                            <input
                              type="text"
                              name="essayTitle"
                              className="form-control mb-4 p-3 font-xs border-light bg-light text-center"
                              placeholder="Insira um título"
                              value={essayTitle}
                              onChange={this.handleChange}
                            />
                            <textarea
                              className="form-control mb-4 p-4 font-xs lh-28 border-light bg-light"
                              rows="10"
                              name="essayText"
                              value={essayText}
                              onChange={this.handleChange}
                              placeholder="Comece a digitar sua redação aqui..."
                              required
                            ></textarea>
                            <button
                              type="submit"
                              className="form-control text-center style2-input text-white fw-600 bg-current border-0 p-0"
                            >
                              Enviar Redação
                            </button>
                          </form>
                        ) : (
                          <div className="text-center">
                            <div className="alert alert-success font-xs fw-500">
                              <i className="feather-check-circle mr-2"></i>
                              Redação enviada com sucesso! Aguarde o retorno da
                              instituição via e-mail.
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Appfooter />

          {/* Modais de Confirmação e Aviso */}
          <Modal
            show={showModal}
            onHide={() => this.setState({ showModal: false })}
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirmação de Início</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Tem certeza de que deseja iniciar a prova? A partir do início, o
              tempo será contado e não poderá ser pausado.
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => this.setState({ showModal: false })}
              >
                Cancelar
              </Button>
              <Button variant="primary" onClick={this.confirmStartExam}>
                Iniciar
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showWarningModal} onHide={this.closeWarningModal}>
            <Modal.Header closeButton>
              <Modal.Title>Aviso de Redação</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Por favor, revise o conteúdo antes de confirmar o envio para
              evitar reprova no vestibular.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={this.closeWarningModal}>
                Ok, vou revisar
              </Button>
              <Button variant="danger" onClick={this.proceedWithSubmission}>
                Continuar com o envio
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Fragment>
    );
  }
}

export default ExamPage;
