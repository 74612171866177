import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class Adminsidebar extends Component {
  render() {
    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }
    return (
      <ul
        className="navbar-nav bg-gradient-primary sidebar sidebar-dark scroll-bar"
        id="accordionSidebar"
      >
        <div
          className="sidebar-brand d-flex align-items-start justify-content-start"
          href="/admin"
        >
          <img
            src={`${process.env.PUBLIC_URL}/static/images/unifatecie-white-logo.png`}
            alt="Logo Unifatecie"
            className="logo-image"
          />
          <button className="ms-auto mt-2 d-lg-none" id="sidebarClose">
            <i className="ti-close text-white font-sm text-grey-100 "></i>
          </button>
        </div>

        <div className="sidebar-heading">Menu</div>

        <li className="nav-item">
          <NavLink className="navi-link" to="/admin">
            <i className="feather-pie-chart mr-2"></i>
            <span>Indicadores (demo)</span>
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink className="navi-link" to="/admin-essays">
            <i className="feather-pie-chart mr-2"></i>
            <span>Redações</span>
          </NavLink>
        </li>

        <div className="sidebar-heading">Autenticações</div>

        <li className="nav-item">
          <NavLink className="navi-link" to="/login">
            <i className="feather-award mr-2"></i>
            <span>Login Admin</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="navi-link" to="/register">
            <i className="feather-user mr-2"></i>
            <span>Registro Admin</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="navi-link" to="/forgot">
            <i className="feather-lock mr-2"></i>
            <span>Alterar Senha</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="navi-link" to="/notfound">
            <i className="feather-log-out mr-2"></i>
            <span>Consultar Senha</span>
          </NavLink>
        </li>

        <div className="mt-3"></div>
      </ul>
    );
  }
}

export default Adminsidebar;
