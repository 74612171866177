// react/src/pages/AdminEssays.js
import React, { Component, Fragment } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "../utils/axios"; // Usando a instância configurada de axios

import Adminsidebar from "../components/Adminsidebar";
import AdminTopnav from "../components/AdminTopnav";
import Adminfooter from "../components/Adminfooter";
import Pagination from "../components/Pagination";

class AdminEssays extends Component {
  constructor() {
    super();
    this.state = {
      essays: [],
      showModal: false,
      selectedEssay: null,
      grade: "",
      feedback: "",
      error: "",
      userEmail: "", // Adiciona userEmail ao estado inicial
    };
  }

  componentDidMount() {
    this.fetchEssays();
  }

  fetchEssays = async () => {
    try {
      // Fazer a requisição de redações usando a instância de axios configurada
      const response = await axios.get("/api/essays/");
      const essaysData = Array.isArray(response.data) ? response.data : [];
      this.setState({ essays: essaysData });
    } catch (error) {
      console.error("Erro ao buscar redações:", error);
    }
  };

  handleGradeChange = (event) => {
    this.setState({ grade: event.target.value });
  };

  handleFeedbackChange = (event) => {
    this.setState({ feedback: event.target.value });
  };

  handleSubmitGrade = async (essayId) => {
    const { grade, feedback, userEmail } = this.state;
    try {
      // Enviar nota e feedback usando a instância de axios configurada
      await axios.post("/api/grade-essay/", {
        essay_id: essayId,
        grade: grade,
        feedback: feedback,
        user_email: userEmail, // Inclui o e-mail do usuário na requisição
      });

      alert("Nota e feedback atribuídos com sucesso!");
      this.setState({
        showModal: false,
        grade: "",
        feedback: "",
        selectedEssay: null,
        userEmail: "", // Limpa o e-mail do estado após o envio
      });
      this.fetchEssays();
    } catch (error) {
      console.error("Erro ao atribuir nota e feedback:", error);
      this.setState({
        error: "Erro ao atribuir nota e feedback. Tente novamente mais tarde.",
      });
    }
  };

  handleOpenModal = (essay) => {
    this.setState({
      showModal: true,
      selectedEssay: essay,
      grade: "",
      feedback: "",
      userEmail: essay.user.email, // Adiciona o e-mail do usuário ao estado
    });
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false,
      selectedEssay: null,
      grade: "",
      feedback: "",
    });
  };

  render() {
    const { essays, showModal, selectedEssay, grade, feedback } = this.state;

    return (
      <Fragment>
        <div id="wrapper">
          <Adminsidebar />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <AdminTopnav />
              <div className="container px-3 py-4">
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <div className="card border-0 mt-2 rounded-10">
                      <div className="card-body d-flex p-4 pb-0">
                        <h4 className="font-xss text-grey-800 mt-3 fw-700">
                          Redações para Correção
                        </h4>
                      </div>
                      <div className="card-body p-4">
                        <div className="table-responsive">
                          <table className="table table-admin mb-0">
                            <thead className="bg-greylight rounded-10 ovh">
                              <tr>
                                <th className="border-0">ID</th>
                                <th className="border-0">Estudante (RA)</th>
                                <th className="border-0">Email</th>{" "}
                                {/* Nova coluna para exibir o e-mail */}
                                <th className="border-0">Título</th>
                                <th className="border-0">Data de Envio</th>
                                <th className="border-0">Status</th>
                                <th className="border-0">Nota</th>
                                <th className="border-0 text-end">Ação</th>
                              </tr>
                            </thead>

                            <tbody>
                              {essays.map((essay, index) => (
                                <tr key={index}>
                                  <td>{essay.id}</td>
                                  <td>
                                    {essay.user.full_name ||
                                      essay.user.username}
                                  </td>
                                  <td>{essay.user.email}</td>{" "}
                                  {/* Exibe o e-mail do usuário */}
                                  <td>{essay.title}</td>
                                  <td>
                                    {new Date(
                                      essay.created_at
                                    ).toLocaleDateString("pt-BR")}
                                  </td>
                                  <td>{essay.status}</td>
                                  <td>
                                    {essay.grade ? essay.grade : "Não Avaliado"}
                                  </td>
                                  <td className="product-remove text-end">
                                    <button
                                      onClick={() =>
                                        this.handleOpenModal(essay)
                                      }
                                      className="btn btn-primary"
                                    >
                                      Atribuir Nota
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <Pagination divClass="pagination justify-content-center mt-5" />
                  </div>
                </div>
              </div>
              <Adminfooter />
            </div>
          </div>
        </div>

        <Modal show={showModal} onHide={this.handleCloseModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Atribuir Nota</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: "70vh", overflowY: "auto" }}>
            {selectedEssay && (
              <>
                <h5>Redação: {selectedEssay.title}</h5>
                <p>{selectedEssay.body}</p>
                <input
                  type="number"
                  className="form-control mb-2"
                  placeholder="Digite a nota"
                  value={grade}
                  onChange={this.handleGradeChange}
                />
                <textarea
                  className="form-control"
                  placeholder="Digite o feedback"
                  value={feedback}
                  onChange={this.handleFeedbackChange}
                ></textarea>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseModal}>
              Cancelar
            </Button>
            <Button
              variant="primary"
              onClick={() => this.handleSubmitGrade(selectedEssay.id)}
            >
              Salvar Nota e Feedback
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}

export default AdminEssays;
